//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            tabBarIndex: 0,
            dialogVisible: false,
            tabBarList: [
                {
                    label: 'ALL',
                    params: {}
                },
                {
                    label: 'NO USE',
                    params: {
                        status: 1
                    }
                },
                {
                    label: 'USED',
                     params: {
                        status: 2
                    }
                },
                // {
                //     label: '已過期',
                //     params: {
                //         status: 3
                //     }
                // }
			],
            orderList: [],
            total: -1,
            page: 1,
            couponList: [],
        }
    },
    methods: {
         async getCouponList(page) {
            page && (this.page = 1)
            const res = await this.$http.get('/user.coupon', {
                page: this.page++,
                ...this.tabBarList[this.tabBarIndex].params
            })
            if (res.code !== 0) {
                return this.$message.error(res.message);
            }
            let data = res.data
            this.total = data.total
            this.orderList = [...data.list, ...this.orderList]
        },
        async getCoupon() {
            this.dialogVisible = true
            const res = await this.$http.get('/coupon', {
                page: 1,
            })
            if (res.code !== 0) {
                return this.$message.error(res.message);
            }
            res.data.list.forEach(item => {
                item.is_used = 0
            })
            this.couponList = res.data.list
        },
        //滚动加载
        load() {
            this.getCouponList()
        },
        useCoupon(val) {
            if (val == 0) {
                this.$router.push({name:'Home'})
            }
        },
        handleClose() {
            this.dialogVisible = false
        },
        async receive(index,is_used) {
            if(is_used == 0){
                const list = this.couponList;
                const coupon_id = list[index].coupon_id
                const res = await this.$http.post('/user.coupon/receive', {
                    coupon_id,
                })
                if (res.code !== 0) {
                    return this.$message.error(res.message);
                }
               
                this.$message({
                    message: res.message,
                    type: 'success'
                });
                list[index].is_used = 1
                this.getCouponList()
            }else{
                this.dialogVisible = false
                this.$router.push({name:'Home'})
            }
        },
    },
     watch: {
        tabBarIndex:function(val){
            this.orderList = []
            this.total = -1
            this.page = 1
            setTimeout(() => {
                this.getCouponList()
            }, 100);
        }
    }
}
