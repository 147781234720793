//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            tabBarIndex: 0,
            tabBarList: [
                {
                    label: 'ALL',
                    params: {}
                },
                {
                    label: 'waitPay',
                    params: {
                        order_status: 2,
                        tab: 'waitPay'
                    }
                },
                {
                    label: 'waitSend',
                    params: {
                        order_status: 4,
                        tab: 'waitSend'
                    }
                },
                {
                    label: 'waitConfirm',
                    params: {
                        order_status: 6,
                        tab: 'waitConfirm'
                    }
                },
                {
                    label: 'waitComment',
                    params: {
                        is_comment: 0,
                        tab: 'waitComment'
                    }
                },
			],
            orderList: [],
            total: -1,
            page: 1,
        };
    },

    created() {},

    methods: {
        // // 获取订單统计
        // async getOrderStatistics() {
        //     const res = await uni.$http.get('/order.statistics')
        //     if (res.data.code !== 0) {
        //         if (res.data.code === 401) this.tabBarList1.forEach(item => item.count = 0)
        //         return
        //     }
        //     // let info = this.loginUserInfo
        //     let data = res.data.data
        //     this.tabBarList1.forEach(item => {
        //         if (data[item.tabCode] !== undefined) item.count = data[item.tabCode]
        //     })
        // },

        //获取訂單列表
        async getOrderList(page) {
            page && (this.page = 1)
            // if (this.total >= this.orderList.length) return
            const res = await this.$http.get('/order', {
                page: this.page++,
                ...this.tabBarList[this.tabBarIndex].params
            })
            if (res.code !== 0) {
                return this.$message.error(res.message);
            }
            let data = res.data
            this.total = data.total
            this.orderList = [...data.list, ...this.orderList]
        },

        // 取消订單
		orderCancel(order_sn) {
			this._doConfirmAndRequest('確定要取消吗？', '/order/cancel', order_sn)
		},
		orderCancel1(){
			  let _this = this
			  _this.$message({message: '請登錄APP取消訂單'})    
		},

        //滚动加载
        load() {
            this.getOrderList()
        },

        // 提示并請求
        _doConfirmAndRequest(content, api, order_sn) {
            let _this = this
            this.$confirm(content, '提示', {
                confirmButtonText: '確定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$http.post(api, {
                    order_sn,
                }).then(res => {
                    if(res.code !== 0) {
                        return _this.$message.error(res.message);
                    }else{
                        _this.$message({message: res.message, type: 'success'})
                        _this.getOrderList(1)

                    }
                })
            }).catch(() => {
                 _this.$message({message: '已取消'})    
            })
        },

        //訂單详情页面
        goDetail(order) {
             this.$router.push({ name: 'OrderDteail', params:{order}})
        },
        
    },
    watch: {
        tabBarIndex:function(val){
            this.orderList = []
            this.total = -1
            this.page = 1
            this.getOrderList()
        }
    }
};
